<div *ngIf="article" class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h1>
                        {{article?.title}}
                    </h1>

                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png"
                alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</div>

<section class="single-blog-area" *ngIf="article">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image">
                        <img src="{{server + article?.image?.public_url}}"
                            alt="{{article?.title}}">
                    </div>

                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li><span>تم النشر بتاريخ:</span> <a
                                        routerLink="/">
                                        {{article?.createdAt | date: 'fullDate'}}
                                    </a></li>
                            </ul>
                        </div>



                        <div *ngIf="article && article.content " class="mt-4" [innerHTML]="article.content">

                        </div>
                    </div>

                    <div class="article-footer">
                        <div class="article-tags">

                        </div>
                        <!--
                        <div class="article-share">
                            <ul class="social">
                                <li><span>Share:</span></li>
                                <li><a href="#" target="_blank"><i class="fab
                                            fa-facebook-f"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab
                                            fa-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab
                                            fa-instagram"></i></a></li>
                            </ul>
                        </div>
                        -->
                    </div>




                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">


                    <section class="widget widget_recent_entries">
                        <h2 class="widget-title">
                            احدث منشورات
                        </h2>

                        <ul>
                            <li *ngFor="let article of latestArticles"><a
                                    routerLink="/article/{{article.id}}">
                                    {{article.title}}
                                </a></li>

                        </ul>
                    </section>

                </aside>
            </div>
        </div>
    </div>
</section>
