<div class="container" id="services">
  <div class="section-title">
    <h2>خدماتنا</h2>
    <div class="bar"></div>
    <p class="fs-5">
      تقدم المنصة عديد الخدمات لمختلف المداخيلن في عملية البيع،الشراء و النقل
      للمنتجات الفلاحية في المملكة.
    </p>
  </div>

  <div class="row">
    <div class="col-lg-4 col-md-6">
      <div class="single-features">
        <div class="icon">
          <i class="fa fa-credit-card"></i>
        </div>
        <h3>بيع</h3>
        <p class="fs-5">
          خدمات "خراج" تجعل التفكير في تسويق محاصيلكم ومنتجاتكم شيء من الماضي !!
        </p>
        <button routerLink="seller-description" class="mt-3 btn btn-success">
          المزيد من التفاصيل
        </button>
      </div>
    </div>

    <div class="col-lg-4 col-md-6">
      <div class="single-features">
        <div class="icon">
          <i class="fa fa-cart-plus"></i>
        </div>
        <h3>شراء</h3>
        <p class="fs-5">
          لا تبحث عن مصادر لشراء المحاصيل والمنتجات جميعها تنتظركم في "خراج"
        </p>
        <button routerLink="buyer-description" class="mt-3 btn btn-success">
          المزيد من التفاصيل
        </button>
      </div>
    </div>

    <div class="col-lg-4 col-md-6">
      <div class="single-features">
        <div class="icon">
          <i class="fa fa-bus"></i>
        </div>
        <h3>نقل</h3>
        <p class="fs-5">
          يبحث الناقلون عن الطلبات في الأسواق ولكن مع خدمات "خراج" الطلبات هي من
          تبحث عن الناقلين
        </p>
        <button
          routerLink="transporter-description"
          class="mt-3 btn btn-success"
        >
          المزيد من التفاصيل
        </button>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="section-title">
    <h2>شركاء النجاح</h2>
    <div class="bar"></div>
    <p class="fs-5">فخورين بشركاء النجاح</p>
  </div>

  <div class="row">
    <div class="col-lg-4 col-md-6">
      <div class="single-features">
        <div class="icon">
          <img
          style="width: 300px; height: 300px;"
            src="https://investorksa.net/wp-content/uploads/2023/04/logo.png"
          />
        </div>
        <h3>منصة مستثمر</h3>
        <p class="fs-5">
           منصة مستثمر هي علامة تجارية
          مسجلة تحت مسمى مؤسسة منجزات للتسويق الالكتروني
        </p>
        <a href="https://investorksa.net" class="mt-3 btn btn-success">
          المزيد من التفاصيل
        </a>
      </div>
    </div>

    <div class="col-lg-4 col-md-6">
      <div class="single-features">
        <div class="icon">
          <img
          style="width: 300px; height: 300px;"
            src="https://static1.s123-cdn-static-a.com/uploads/5841415/400_62552e88dc14e.jpg"
          />
        </div>
        <h3>الجمعية التعاونية الزراعية التسويقية بالشعف</h3>
        <p class="fs-5">جمعية تعاونية تعمل في مجال التسويق الزراعي</p>
        <a href="https://www.shaaf347.com/" class="mt-3 btn btn-success">
          المزيد من التفاصيل
        </a>
      </div>
    </div>

    <div class="col-lg-4 col-md-6">
      <div class="single-features">
        <div class="icon">
          <img
          style="width: 300px; height: 300px;"
            src="https://asdam.my.canva.site/dark-gray-website/images/7e300d7485c64a520b1c461227dcedf9.png"
          />
        </div>
        <h3>ASDAM</h3>
        <p class="fs-5">
          معمل سعودي يزرع جميع انواع الفطر ذو جودة ولذة ويحل مشكلة استيراد الفطر
        </p>
        <a
          href="https://asdam.my.canva.site/dark-gray-website#home"
          class="mt-3 btn btn-success"
        >
          المزيد من التفاصيل
        </a>
      </div>
    </div>
  </div>
</div>
