import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ArticleService } from '../../../services/article.service';
import { Meta, Title } from '@angular/platform-browser';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
  articleId: string;
  article: any;
  server: string = environment.blobURL;
  latestArticles: any;

  constructor(private articleService: ArticleService, private route: ActivatedRoute,
    private meta: Meta, private titleService: Title, private seoService: SeoService,
  ) {

  }

  ngOnInit(): void {
    

    if (this.seoService.isServer()) {
      this.route.params.subscribe(params => {
        this.meta.removeTag('description');

        this.articleId = params['id']
        this.getArticle(this.articleId);
      });
      this.getLatest()
    } else {
      this.route.params.subscribe(params => {
        this.meta.removeTag('description');
        this.articleId = params['id']
        this.getArticle(this.articleId);
      });
      this.getLatest()
    }
  }

  getArticle = (articleId: string) => this.articleService.getOne(articleId).subscribe(
    (res: any) => {
      this.article = res.data
      if (this.article.pageTitle) this.titleService.setTitle(this.article.pageTitle);


      this.meta.removeTag('keywords');
      this.meta.removeTag('url');

      this.meta.addTags([
        { name: 'description', content: this.article.pagedescription || '' },
        { name: 'keywords', content: this.article.keywords || '' },
        { name: 'url', content: this.article.url || '' }
      ]);

      if (this.article.canonical) {
        // Canonical
        const link: HTMLLinkElement = this.createLinkElement();
        link.setAttribute('rel', 'canonical');
        link.setAttribute('href', this.article.canonical);
        document.head.appendChild(link);
      }

      if (this.article.structuredData) {
        // Structured Data
        const script: HTMLScriptElement = this.createScriptElement();
        script.type = 'application/ld+json';
        script.text = JSON.stringify(this.article.structuredData);
        document.head.appendChild(script);
      }

    }
  )

  getLatest = () => this.articleService.getLatest().subscribe(
    (res: any) => this.latestArticles = res.data
  )

  private createLinkElement(): HTMLLinkElement {
    const link: HTMLLinkElement = document.createElement('link');
    return link;
  }

  private createScriptElement(): HTMLScriptElement {
    const script: HTMLScriptElement = document.createElement('script');
    return script;
  }
}
