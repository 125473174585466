<div class="container">
    <div class="video-content">
        <h3> 
          انضم إلينا اليوم وأبدأ البيع والشراء
        </h3>

        <h4 class="text-white">
          دورنا نضمن لك الجودة
        </h4>
    
        <div class="about-btn mt-5">
          <a href="https://app.kharaaj.com" target="_self"
              class="default-btn">
              تسجيل <span></span></a>
      </div>

       
    </div>

   
</div>

