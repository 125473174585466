/* eslint-disable @typescript-eslint/ban-types */
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import {
  Router,
  NavigationStart,
  NavigationCancel,
  NavigationEnd,
  ActivatedRoute
} from '@angular/router';
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { filter } from 'rxjs/operators';
import { Title, Meta, DomSanitizer } from '@angular/platform-browser';
import { SeoService } from './services/seo.service';
declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    Location,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
  ],
})
export class AppComponent implements OnInit {
  location: any;
  routerSubscription: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private seoService: SeoService,
    private titleService: Title,
    private metaService: Meta,
    private sanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  private createLinkElement(): HTMLLinkElement {
    const link: HTMLLinkElement = document.createElement('link');
    return link;
  }

  private createScriptElement(): HTMLScriptElement {
    const script: HTMLScriptElement = document.createElement('script');
    return script;
  }

  ngOnInit() {

    this.recallJsFuntions();

    this.seoService.getAll().subscribe((res: any) => {
      const config = res.data;
      const url = this.router.url;

      // Title and Description
      this.titleService.setTitle(config.title);

      const tags = [
        { name: 'description', content: config.description },
        { name: 'robots', content: config.robots },
        { name: 'keywords', content: config.keywords },
        { property: 'og:title', content: config.og.title },
        { property: 'og:description', content: config.og.description },
        { property: 'og:image', content: config.og.image },
        { property: 'og:url', content: config.og.url },
        { property: 'og:type', content: config.og.type },
        { property: 'og:site_name', content: config.og.site_name },
        { property: 'og:locale', content: config.og.locale },
        { name: 'twitter:card', content: config.twitter.card },
        { name: 'twitter:title', content: config.twitter.title },
        { name: 'twitter:image', content: config.twitter.image },
        { name: 'twitter:site', content: config.twitter.site },
        { name: 'twitter:description', content: config.twitter.description },
        { name: 'twitter:creator', content: config.twitter.creator }
      ];

      // Remove description and keywords tags if on 'article/:id' route
      if (url.startsWith('/article/')) {
        tags.splice(tags.findIndex(tag => tag.name === 'description'), 1);
        tags.splice(tags.findIndex(tag => tag.name === 'keywords'), 1);
      } else {
        // Structured Data
        const script: HTMLScriptElement = this.createScriptElement();
        script.type = 'application/ld+json';
        script.text = JSON.stringify(config.structuredData);
        script.id = 'structuredDataScript'; // Add an id to the script element
        document.head.appendChild(script);
      }

      this.metaService.addTags(tags);

      // Canonical
      const link: HTMLLinkElement = this.createLinkElement();
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', config.canonical);
      document.head.appendChild(link);


    });
  }

  recallJsFuntions() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        $('.preloader').fadeIn('slow');
      }
    });
    this.routerSubscription = this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd || event instanceof NavigationCancel
        )
      )
      .subscribe((event) => {
        $.getScript('../assets/js/main.js');
        $('.preloader').fadeOut('slow');
        this.location = this.router.url;
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      });
  }
}
