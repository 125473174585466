<div class="container" id="about">
    <div class="section-title">
        <h2>حول منصتنا</h2>
        <div class="bar"></div>
        <p class="fs-5">
            "خراج" المنصة الإلكترونية الأولى من نوعها في عالم سوق الخضار والفواكه في المملكة العربية السعودية التي تجمع بين الاحترافية والموثوقية والضمان إضافة إلى سهولة الاستخدام وموائمتها لمستهدفات رؤية المملكة 2030.
        </p>
        <div class="mt-4 overview-btn">
            <a style="cursor:pointer;" (click)="downloadFile()" class="default-btn">
                تنزيل pdf (مزيد من التفاصيل)
                <span></span></a>
        </div>
    </div>

    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="about-content">
                <h3>
                    مقدمة عامة
                </h3>
                <div class="bar"></div>
              
                <p class="fs-5">
                    تأتي منصة "خراج" لتقديم حلول جوهرية ودعائم لوجستية لإشكاليات سوق الخضار والفواكه، حيث نوفر لك نظامًا موثوقًا لعرض الأسعار وعمليات البيع والشراء بآليات واضحة وطرق دفع آمنة. تعتمد منصتنا على تسجيل المستخدمين بوثائق رسمية وأرقام حسابات بنكية، مما يضمن لك بيئة تجارية احترافية وقانونية تحمي جميع الأطراف المعنية: البائع، المشتري، ووسيلة النقل. انضم إلينا واستفد من تجربة تجارة سلسة ومضمونة في عالم الزراعة!
                </p>
                <div class="about-btn">
                    <a href="https://app.kharaaj.com" target="_self" class="default-btn">
                        نسخة الويب  <span></span></a>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="about-image">
                <img src="assets/img/about.webp" alt="image">
            </div>
        </div>
    </div>
</div>